/* font.css */
@font-face {
  font-family: "SUIT";
  font-weight: 100;
  src: url("../../public/fonts/SUIT-ttf/SUIT-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 200;
  src: url("../../public/fonts/SUIT-ttf/SUIT-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 300;
  src: url("../../public/fonts/SUIT-ttf/SUIT-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 400;
  src: url("../../public/fonts/SUIT-ttf/SUIT-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 500;
  src: url("../../public/fonts/SUIT-ttf/SUIT-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 600;
  src: url("../../public/fonts/SUIT-ttf/SUIT-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 700;
  src: url("../../public/fonts/SUIT-ttf/SUIT-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SUIT";
  font-weight: 800;
  src: url("../../public/fonts/SUIT-ttf/SUIT-ExtraBold.ttf") format("truetype");
}

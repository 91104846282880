.tutorial {
  padding-top: 150px;
  padding-bottom: 50px;
  width: 100%;
}
.tutorial__inner {
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 100%;
}
.tutorial pre {
  white-space: pre-wrap;
  cursor: text;
  word-break: break-word;
  font-size: var(--font-size--text3);
  line-height: 1.6;
}
.tutorial__code .small-font {
  padding: 5px;
  font-size: var(--font-size--text4);
}
.tutorial__code-description h1 {
  font-size: var(--font-size--h3);
  font-weight: 600;
  padding: 30px 0;
  border-top: 3px solid rgb(232, 232, 232);
  border-bottom: 3px solid rgb(232, 232, 232);
}

.tutorial__inner .sub-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: var(--font-size--text1);
}

.tutorial-image-Box_green {
  margin-bottom: 40px;
}

.tutorial-image-Box_green img {
  width: 200px;
  /* height: 35px; */
}

.tutorial-image-Box_skeleton {
  display: flex;
  justify-content: center;
}

.tutorial-image-Box_skeleton img {
  max-width: 400px;
  width: 90%;
}

.tutorial-image-Box_page_template {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorial-image-Box_page_template img {
  max-width: 250px;
  width: 90%;
  margin-bottom: 15px;
}

.tutorial-image-Box_page_template span {
  font-size: var(--font-size--text2);
}

.tutorial__inner h2 {
  font-size: var(--font-size--h4);
  font-weight: 600;
}
.tutorial__inner h3 {
  font-size: var(--font-size--sub-content);
  font-weight: 600;
}
.tutorial__inner section {
  padding: 0 var(--horizontal-space);
}
.tutorial__code-description {
  font-size: 16px;
  margin-bottom: 50px;
}
.tutorial__code div.wrap {
  margin: 10px 0 30px;
}

.tutorial-download-Box {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.tutorial-download-Box a {
  display: inline-block;
  padding: 20px 30px;
  background-color: #ddd;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
}

.tutorial__code h1 {
  font-size: var(--font-size--h3);
  font-weight: 600;
  padding: 30px 0;
  border-bottom: 3px solid rgb(232, 232, 232);
  margin-bottom: 40px;
}

.tutorial__code .code-sub-title {
  font-size: var(--font-size--text1);
}

.tutorial__code p {
  font-size: var(--font-size--text2);
  font-weight: 600;
}

.tutorial__code > ul {
  margin: 20px 0;
  padding: 0 20px;
}

.tutorial__code ul li {
  font-size: var(--font-size--text2);
}

.tutorial-image-Box_badge_template {
  display: flex;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.tutorial-image-Box_badge_template img {
  max-width: 300px;
  width: 90%;
}

.tutorial__html-code,
.tutorial__css-code {
  padding: 50px;
  font-size: 16px;
  background-color: #eee;
  cursor: text;
  border-radius: 8px;
}
.tutorial__html-code p,
.tutorial__css-code p {
  margin-bottom: 20px;
}
.tutorial__code__tab {
  margin-bottom: 20px;
  display: flex;
}
.tutorial__code__tab li {
  padding: 7px 25px;
  cursor: pointer;
  border-bottom: 2px solid gray;
}
.tutorial__code__tab li.active {
  font-weight: 600;
  color: #fff;
  background-color: gray;
  border-radius: 4px 4px 0 0;
}
.tutorial-img a {
  display: inline-block;
  padding: 20px 30px;
  background-color: #ddd;
  border-radius: 7px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .tutorial__inner {
    width: auto;
    margin: 0 var(--horizontal-space);
  }
  .tutorial-image-Box_green {
    margin-bottom: 10px;
  }
  .tutorial-image-Box_green img {
    display: block;
    margin: 0 auto;
    width: 130px;
    height: auto;
  }
  .tutorial__html-code,
  .tutorial__css-code {
    padding: 30px;
  }
  .tutorial__code__tab li.active {
    border-radius: 0;
  }
}

@media screen and (max-width: 500px) {
  .tutorial__code__tab {
    flex-direction: column;
  }
  .tutorial__code__tab li {
    text-align: center;
  }
  .tutorial__code__tab li.active {
    color: #fff;
    background-color: gray;
  }
}
/* service-description */
.home .home-service-description {
  padding: 150px 0;
  background-color: #f0f0f0;
  position: relative;
}
.home .home-service-description .title {
  padding-bottom: 100px;
}
.home .home-service-description .title p {
  font-size: var(--font-size--h2);
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0;
  position: relative;
}
.home .home-service-description .title p:nth-child(1) {
  opacity: 0;
  position: relative;
  transform: translateY(150%);
  transition: all 500ms;
}
.home .home-service-description .title p:nth-child(2) {
  opacity: 0;
  position: relative;
  transform: translateY(150%);
  transition: all 1000ms;
}
.home .home-service-description .title p:nth-child(3) {
  opacity: 0;
  position: relative;
  transform: translateY(150%);
  transition: all 1500ms;
}

.home .home-service-description .title.action p:nth-child(1),
.home .home-service-description .title.action p:nth-child(2),
.home .home-service-description .title.action p:nth-child(3) {
  opacity: 1;
  transform: translateY(0%);
}

.home .home-service-description-img {
  position: absolute;
  max-width: var(--horizontal-width);
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home .home-description__content {
  height: 100%;
}
.home .home-description__content ul {
  display: flex;
  justify-content: space-between;
}
.home .home-description__content ul li {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: calc(100% / 4);
  height: 100%;
}
.home .home-description__content ul li:last-child {
  border: none;
}
.home .home-description__content ul li > div {
  display: flex;
  align-items: center;
  height: 110px;
}
.home .home-description__content ul li img {
  display: block;
  height: 100px;
}
.home .home-description__content ul li p {
  font-size: var(--font-size--text2);
  text-align: center;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .home .home-service-description {
    padding: 60px 0;
  }
  .home .home-service-description .title {
    padding-bottom: 60px;
  }
  .home .home-description__content ul {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 40px;
  }
  .home .home-description__content ul li {
    padding: 0 15px;
    width: 50%;
    gap: 10px;
  }
  .home .home-description__content ul li > div {
    height: 70px;
  }
  .home .home-description__content ul li p {
    height: 40px;
  }
  .home .home-description__content ul li img {
    margin-bottom: 15px;
    height: 60px;
  }
}

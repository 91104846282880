/* improvement */
.home .home-improvement {
  width: 100%;
}
.home .home-improvement__title {
  width: 100%;
  background-color: #000;
}
.home .home-improvement__title .home__inner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 205px 0;
  background-repeat: no-repeat;
  background-image: url(../../../../public/images/home/improvement_bg.png);
  background-size: 118%;
  background-position: 50% 100%;
}
.home .home-improvement__title h2 {
  font-size: var(--font-size--large);
  font-weight: 600;
  text-align: left;
  color: #00ffa3;
  line-height: 1;
}
.home .home-improvement__title .line_img {
  margin: 0 20px;
  flex: 1;
}
.home .home-improvement__title .line_img img {
  width: 100%;
}
.home .home-improvement__title .line_img img.tablet {
  display: none;
}
.home .home-improvement__title .text {
  width: 450px;
}
.home .home-improvement__title .text p {
  font-weight: 600;
  white-space: nowrap;
}
.home .home-improvement__title .text p:nth-of-type(1) {
  color: #005b3a;
  font-size: 28px;
  line-height: 50px;
}
.home .home-improvement__title .text p:nth-of-type(2) {
  color: #007a4e;
  font-size: 28px;
  line-height: 50px;
}
.home .home-improvement__title .text p:nth-of-type(3) {
  color: #00bc78;
  font-size: 28px;
  line-height: 50px;
}
.home .home-improvement__title .text p:nth-of-type(4) {
  color: #00ffa3;
  font-size: 36px;
  line-height: 60px;
}
.home .home-improvement__content {
  padding: 160px 0;
}
.home .home-improvement__content .home__inner {
  display: flex;
}
.home .home-improvement__content .minus,
.home .home-improvement__content .plus {
  padding-top: 80px;
  padding-left: 80px;
  width: 50%;
  background-repeat: no-repeat;
  background-size: 160px;
}
.home .home-improvement__content .minus {
  background-image: url(../../../../public/images/home/improvement_icon_minus.png);
}
.home .home-improvement__content .minus > div,
.home .home-improvement__content .plus > div {
  padding-top: 5px;
  padding-left: 7px;
  background-color: #fff;
  border-radius: 10px;
}
.home .home-improvement__content .minus > div h3,
.home .home-improvement__content .plus > div h3 {
  padding-bottom: 20px;
  font-size: var(--font-size--h3);
  font-weight: 600;
  color: #bbb8b8;
}
.home .home-improvement__content .minus > div ul,
.home .home-improvement__content .plus > div ul {
  display: flex;
}
.home .home-improvement__content .minus > div ul li span,
.home .home-improvement__content .plus > div ul li span {
  padding: 10px 20px;
  display: inline-block;
  border-radius: 40px;
  font-size: var(--font-size--text2);
  font-weight: 500;
}
.home .home-improvement__content .minus > div ul li span {
  color: #6f710f;
  background-color: #feffc5;
}
.home .home-improvement__content .minus > div ul li,
.home .home-improvement__content .plus > div ul li {
  margin-bottom: 16px;
  margin-right: 15px;
}
.home .home-improvement__content .plus {
  background-image: url(../../../../public/images/home/improvement_icon_plus.png);
}
.home .home-improvement__content .minus > div ul li:first-child {
  width: 215px;
}
.home .home-improvement__content .plus > div ul li:first-child {
  width: 162px;
}
.home .home-improvement__content .plus > div ul li span {
  color: #157961;
  background-color: #c2f9eb;
}
@media screen and (max-width: 1024px) {
  .home .home-improvement__title .home__inner {
    padding: 120px 0;
    max-width: 450px;
    flex-direction: column;
  }
  .home .home-improvement__title h2 {
    width: 100%;
    text-align: left;
  }
  .home .home-improvement__title .line_img img.pc {
    display: none;
  }
  .home .home-improvement__title .line_img img.tablet {
    display: block;
  }
  .home .home-improvement__title .line_img {
    margin: 0;
    margin-top: 17px;
    width: 100%;
  }
  .home .home-improvement__title .line_img img {
    margin-left: 115px;
    width: 60px;
  }
  .home .home-improvement__title .text {
    width: 100%;
    text-align: left;
  }
  .home .home-improvement__content .home__inner {
    flex-direction: column;
    gap: 50px;
  }
  .home .home-improvement__content .home__inner > div {
    margin: 0 auto;
    width: 570px;
  }
}
@media screen and (max-width: 767px) {
  .home .home-improvement__content {
    padding: 100px 0;
  }
  .home .home-improvement__title {
    padding-top: 0;
    height: auto;
  }
  .home .home-improvement__title .home__inner {
    padding: 80px 0;
    justify-content: left;
    max-width: 280px;
    width: 80%;
  }
  .home .home-improvement__title h2 {
    width: 100%;
    font-size: 40px;
    line-height: 38px;
    text-align: left;
  }
  .home .home-improvement__title .line_img {
    margin-top: 12px;
    width: 100%;
  }
  .home .home-improvement__title .line_img img {
    margin-left: 62px;
    width: 40px;
  }
  .home .home-improvement__title .text {
    width: 100%;
  }
  .home .home-improvement__title .text p:nth-of-type(1),
  .home .home-improvement__title .text p:nth-of-type(2),
  .home .home-improvement__title .text p:nth-of-type(3) {
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }
  .home .home-improvement__title .text p:nth-of-type(4) {
    font-size: 22px;
    line-height: 35px;
    text-align: left;
  }
  .home .home-improvement__title .text p {
    white-space: initial;
  }
  .home .home-improvement__content .minus,
  .home .home-improvement__content .plus {
    padding-top: 45px;
    padding-left: 45px;
    background-size: 100px;
  }
  .home .home-improvement__content .home__inner > div {
    width: 90%;
  }
  .home .home-improvement__content .home__inner > div {
    max-width: 320px;
    width: 90%;
  }
  .home .home-improvement__content .minus > div,
  .home .home-improvement__content .plus > div {
    padding-top: 3px;
    padding-left: 5px;
  }
  .home .home-improvement__content .minus > div h3,
  .home .home-improvement__content .plus > div h3 {
    padding-bottom: 10px;
  }
  .home .home-improvement__content .minus > div ul,
  .home .home-improvement__content .plus > div ul {
    flex-wrap: wrap;
  }
  .home .home-improvement__content .home .home-process {
    padding: 100px 0;
  }
}

:root {
  --font-size--large: 70px;
  --font-size--h1: 62px;
  --font-size--h2: 50px;
  --font-size--h3: 40px;
  --font-size--h4: 28px;
  --font-size--h5: 26px;
  --font-size--h6: 20px;

  --font-size--text1: 24px;
  --font-size--text2: 19px;
  --font-size--text3: 18px;
  --font-size--text4: 16px;
  --font-size--text5: 14px;

  --font-weight--h2: 700;
  --font-weight-default: regular;

  --color--black-3: #333;
  --color--gray-3: #888;
  --color--gray-4: #acacac;
  --color--point: #61c3a5;

  --liheight--text: 1.62;
  --liheight--heading: 1.3;
  --liheight--default: 1.32;

  --input--height: 60px;
  --input--border-line: 1px;
  --input--border-color: #dadada;

  --button--vertical-space: 17px;
  --button-color: #19b786;

  --horizontal-width: 1140px;
  --horizontal-width--responsive: 87%;

  --vertical-space: 130px;

  --inner-max-width: 1920px;
  --border-radius--box: 10px;
  --border-radius--input: 6px;
  --border-radius--button: 7px;
}

@media screen and (max-width: 767px) {
  :root {
    --font-size--large: 46px;
    --font-size--h1: 30px;
    --font-size--h2: 28px;
    --font-size--h3: 24px;
    --font-size--h4: 20px;
    --font-size--h5: 18px;
    --font-size--h6: 16px;

    --font-size--text1: 18px;
    --font-size--text2: 16px;
    --font-size--text3: 15px;
    --font-size--text4: 14px;
    --font-size--text5: 12px;

    --liheight--default: 1.4;

    --horizontal-space: 20px;
    --vertical-space: 100px;

    --input--height: 50px;
    --button--vertical-space: 14px;
  }
}

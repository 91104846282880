/* cross-browsing.css */
input:not([type="checkbox"], [type="radio"]),
textarea {
  box-shadow: none;
  cursor: auto;
  /* ios 안쪽 그림자 삭제 */
  -webkit-appearance: none;
}
input[type="button"],
input[type="submit"] {
  /* 버튼모양 os 상관없이 동일하게 */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
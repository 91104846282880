.page-not-found .inner {
  margin: 0 auto;
  max-width: 650px;
  width: 90%;
}
.page-not-found header .logo {
  display: block;
  margin: 20px auto;
  width: 150px;
}
.page-not-found header .logo img {
  width: 100%;
  cursor: pointer;
}
.page-not-found .excuse-msg__title {
  margin: 120px 0 30px;
}
.page-not-found .excuse-msg__title p {
  font-size: var(--font-size--h6);
  font-weight: 600;
  line-height: 1.6;
}
.page-not-found .excuse-msg__text p {
  font-size: var(--font-size--text3);
  line-height: 1.6;
}
.page-not-found .excuse-msg__text p .service-center {
  margin-left: 4px;
  color: var(--color--point);
  cursor: pointer;
}
.page-not-found .to-main-btn {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  color: #fff;
  font-size: var(--font-size--text3);
  border-radius: 8px;
  background-color: var(--color--point);
  cursor: pointer;
}
.page-not-found .to-main-btn a {
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .page-not-found header .logo {
    width: 120px;
  }
  .page-not-found .excuse-msg__title {
    margin: 70px 0 15px;
  }
  .page-not-found .excuse-msg__text p {
    padding: 6px 0;
    line-height: 1.5;
  }
}
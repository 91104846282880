/* report */
.home .home-report {
  padding-top: 120px;
}
.home .home-report .home-report__title h2 {
  padding-bottom: 80px;
  font-size: var(--font-size--h2);
  text-align: center;
  font-weight: var(--font-weight--h2);
  letter-spacing: 0;
}
.home .home-report__content .report,
.home .home-report__content .badge {
  padding-top: 110px;
  padding-left: 80px;
  width: 100%;
  max-height: 617px;
  height: 46vw;
  background-color: #f8f8f8;
}
.home .home-report__content .badge {
  cursor: pointer;
}
.home .home-report__content .report {
  margin-bottom: 50px;
  background-image: url(../../../../public/images/home/report_bg.png);
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 100% 100%;
}
.home .home-report__content .badge {
  background-image: url(../../../../public/images/home/badge_bg.png);
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 105% 50%;
}
.home .home-report__content .report h3,
.home .home-report__content .badge h3 {
  padding-bottom: 10px;
  font-size: var(--font-size--h3);
  font-weight: 600;
}
.home .home-report__content .report p,
.home .home-report__content .badge p {
  font-size: var(--font-size--text1);
  line-height: 1.6;
}
.home .home-report__content .badge h3,
.home .home-report__content .badge p {
  cursor: pointer;
}
.home .home-report__content .badge .reference {
  padding-top: 5px;
  font-size: var(--font-size--text2);
}
.home .home-report__content .badge .reference.mobile {
  display: none;
}
.home .home-report__content .badge img {
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .home .home-report__content .report,
  .home .home-report__content .badge {
    margin: 0 auto;
    padding-top: 70px;
    padding-left: 70px;
  }
  .home .home-report__content .report {
    margin: 0 auto 50px;
    height: 60vw;
  }
  .home .home-report__content .badge {
    height: 70vw;
  }
  .home .home-report__content .badge {
    background-position: 100% 110%;
  }
  .home .home-partners__logos {
    width: 400%;
  }
  .home .home-report__content .badge .reference.pc {
    display: none;
  }
  .home .home-report__content .badge .reference.mobile {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .home .home-report .home-report__title h2 {
    padding-bottom: 60px;
  }
  .home .home-report__content .report,
  .home .home-report__content .badge {
    padding-top: 30px;
    padding-left: 30px;
    width: 80%;
  }
  .home .home-report__content .report {
    margin-bottom: 30px;
    height: 310px;
    background-size: 55%;
  }
  .home .home-report__content .badge {
    height: 300px;
    background-size: 50%;
    background-position: 100% 120%;
  }
  .home .home-report__content .report h3,
  .home .home-report__content .badge h3 {
    font-size: var(--font-size--h5);
  }
  .home .home-report__content .report p,
  .home .home-report__content .badge p {
    font-size: var(--font-size--text3);
  }
  .home .home-report__content .badge .reference {
    font-size: var(--font-size--text5);
  }
}
@media screen and (max-width: 500px) {
  .home .home-report__content .report,
  .home .home-report__content .badge {
    padding: 30px;
    width: 100%;
  }
  .home .home-report__content .report {
    margin-bottom: 30px;
    height: 310px;
    background-size: 220px;
  }
  .home .home-report__content .badge {
    height: 400px;
    background-size: 300px;
    background-position: 50% 120%;
  }
  .home .home-partners .slide-box {
    height: 62px;
  }
}

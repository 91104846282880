/* subscribe-form */
.home .home-subscribe {
  position: relative;
  padding: 160px 0 160px;
  width: 100%;
}
.home .home-subscribe h2 {
  /* padding-bottom: 80px; */
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  text-align: center;
  letter-spacing: 0;
}
.home .home-subscribe .header-de {
  padding-top: 15px;
  padding-bottom: 80px;
  font-size: var(--font-size--text1);
  text-align: center;
}
.home .home-subscribe form > div {
  display: flex;
}
.home .home-subscribe form ul {
  width: 50%;
}
.home .home-subscribe form ul:first-child {
  padding-right: 20px;
}
.home .home-subscribe form ul:last-child {
  padding-left: 20px;
}
.home .home-subscribe form ul li {
  position: relative;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}
.home .home-subscribe form ul li.agree {
  display: block;
}
.home .home-subscribe form ul li.agree > div {
  display: flex;
  flex-direction: row;
  text-align: center;
}
.home .home-subscribe form ul li.agree > div > div {
  display: flex;
  align-self: center;
}
.home .home-subscribe form ul li.agree div label span {
  text-decoration: underline;
  cursor: pointer;
}
.home .home-subscribe form ul li label.label {
  padding-bottom: 7px;
  font-size: var(--font-size--text2);
  font-weight: 500;
}
.home .home-subscribe form ul li.kwh {
  position: relative;
}
.home .home-subscribe form ul li.kwh div {
  position: relative;
}
.home .home-subscribe form ul li.kwh p {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--font-size--text2);
}
.home .home-subscribe form ul li.textarea {
  padding-bottom: 20px;
}
.home .home-subscribe form ul .radio-box label,
.home .home-subscribe form ul li.agree label {
  display: block;
  font-size: var(--font-size--text2);
  font-weight: 400;
}
.home .home-subscribe form ul li input[type="checkbox"] {
  display: block;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.home .home-subscribe form ul li input[type="text"] {
  height: var(--input--height);
}
.home .home-subscribe form ul li input[type="radio"] {
  margin-right: 7px;
  width: 20px;
  height: 20px;
}
.home .home-subscribe form ul li textarea {
  padding: 18px 18px 0;
  height: 180px;
  width: 100%;
  font-size: var(--font-size--text2);
  border-radius: 5px;
  border: var(--input--border-line) solid var(--input--border-color);
  resize: none;
}
.home .home-subscribe form ul li input[type="text"] {
  width: 100%;
  padding: 0 18px;
  font-size: var(--font-size--text2);
  border-radius: 5px;
  border: var(--input--border-line) solid var(--input--border-color);
}
.home .home-subscribe form ul li input[type="text"]::placeholder {
  font-size: var(--font-size--text2);
}
.home .home-subscribe form ul li.textarea textarea::placeholder {
  font-size: var(--font-size--text2);
}
.home .home-subscribe form ul li .empty {
  margin-top: 10px;
  color: red;
  display: none;
  font-size: var(--font-size--text3);
}
.home .home-subscribe form ul li .empty.show {
  display: block;
}
.home .home-subscribe form ul li .validation-email {
  display: none;
}
.home .home-subscribe form ul li .validation-email.show {
  display: block;
}
.home .home-subscribe form ul li input::placeholder,
.home .home-subscribe form ul li textarea::placeholder {
  font-size: var(--font-size--text5);
  color: var(--color--gray-4);
}
.home .home-subscribe form ul li .radio-box.office {
  display: flex;
  gap: 30px;
}
.home .home-subscribe form ul li .radio-box > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.home .home-subscribe form ul li h1 {
  margin-top: 10px;
  font-size: var(--font-size--text5);
}
.home .home-subscribe form .not-network-msg {
  margin-bottom: 8px;
  font-size: var(--font-size--text3);
  color: red;
  text-align: center;
}
.home .home-subscribe form .btn {
  height: var(--input--height);
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: var(--font-size--text1);
}
.home .home-subscribe form input[type="button"] {
  background-color: #c9c9c9;
}
.home .home-subscribe form input[type="submit"] {
  background-color: var(--button-color);
  cursor: pointer;
}
.home .info {
  margin-top: 0.1em;
  display: block;
  font-size: var(--font-size--text5);
}
@media screen and (max-width: 767px) {
  .home .home-benefit ul li div {
    height: 230px;
  }
  .home .home-subscribe {
    padding-top: 100px 0;
  }
  .home .home-subscribe .header-de {
    padding-top: 10px;
    padding-bottom: 60px;
  }
  .home .home-subscribe form {
    padding: 0 10px;
  }
  .home .home-subscribe form > div {
    flex-direction: column;
    gap: 0px;
  }
  .home .home-subscribe form ul,
  .home .home-subscribe form .btn {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .home .home-subscribe form ul:first-child {
    padding-right: 0;
  }
  .home .home-subscribe form ul:last-child {
    padding-left: 0;
  }
  .home .home-subscribe form ul li {
    padding-bottom: 30px;
  }
  .home .home-subscribe form ul li textarea {
    padding-top: 14px;
    height: 110px;
  }
  .home .home-subscribe form ul li input[type="checkbox"] {
    margin-right: 7px;
    width: 18px;
    height: 18px;
  }
  .home .home-subscribe form ul li input[type="radio"] {
    margin-right: 7px;
    width: 20px;
    height: 20px;
  }
  .home .home-subscribe form ul li h1 {
    margin-top: 0px;
  }
}

/* 개인정보 동의 모달 */
.home .home-subscribe .modal-content {
  background-color: white;
  padding: 50px 70px;
  text-align: center;
  position: relative;
  border-radius: 10px;
  width: 850px;
  top: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.home .home-subscribe .modal-content button {
  background-color: white;
  border: none;
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: var(--font-size--h5);
  font-weight: bold;
  cursor: pointer;
}

.home .home-subscribe .modal-content h1 {
  font-size: var(--font-size--h3);
  margin-bottom: 45px;
}

.home .home-subscribe .modal-content p {
  font-size: var(--font-size--text2);
  margin-bottom: 30px;
  text-align: left;
}

.home .home-subscribe .modal-content h2 {
  font-size: var(--font-size--text2);
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 10px;
  text-align: left;
}
.home .home-subscribe .modal-content ul li {
  text-align: left;
}
@media screen and (max-width: 380px) {
  .home .home-subscribe .modal-content {
    padding: 20px 25px;
    width: 350px;
  }
  .home .home-subscribe .modal-content h1 {
    margin-bottom: 30px;
  }
}

/* 무료구독 신청 모달_로딩중 */
.home .home-subscribe .loading_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
  font-size: var(--font-size--text2);
  background-color: #fff;
  border-radius: 8px;
}
/* 무료구독 신청 모달_성공 */
.home .home-subscribe .success_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 70px 50px;
  border-radius: 16px;
  background-color: #fff;
  text-align: center;
}
.home .home-subscribe .success_modal p {
  line-height: 1.7;
  font-size: var(--font-size--text4);
}
.home .home-subscribe .success_modal button {
  margin-top: 40px;
  padding: 10px 35px;
  font-size: var(--font-size--text4);
  border: none;
  color: #fff;
  border-radius: 8px;
  background-color: #61c3a5;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .home .home-subscribe .success_modal {
    padding: 40px 30px;
    max-width: 300px;
  }
  .home .home-subscribe .success_modal button {
    margin-top: 20px;
  }
  .home .home-subscribe .success_modal p {
    line-height: 1.4;
  }
}
/* 무료구독 신청 모달_에러 */
.home .home-subscribe .error_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 45px;
  width: 300px;
  height: 200px;
  font-size: var(--font-size--text2);
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
}
.home .home-subscribe .error_modal p {
  font-size: var(--font-size--text2);
}
.home .home-subscribe .error_modal button {
  margin-top: 20px;
  padding: 10px 40px;
  color: #fff;
  font-size: var(--font-size--text2);
  border: none;
  border-radius: 5px;
  background-color: var(--button-color);
  cursor: pointer;
}

/* process */
.home .home-process {
  padding: 200px 0;
  background-color: #f9f9f9;
}
.home .home-process__title {
  padding-bottom: 80px;
  text-align: center;
}
.home .home-process__title h2 {
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  line-height: var(--liheight--heading);
  letter-spacing: 0;
}
.home .home-process__title h2.mobile {
  display: none;
}
.home .home-process__content img {
  display: block;
  margin: 0 auto;
  max-width: 1195px;
  width: 100%;
}
.home .home-process__content img.mobile {
  display: none;
}
@media screen and (max-width: 1024px) {
  .home .home-process__content img {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .home .home-process {
    padding: 100px 0;
  }
  .home .home-process__title {
    padding-bottom: 60px;
  }
  .home .home-process__title h2.pc {
    display: none;
  }
  .home .home-process__title h2.mobile {
    display: block;
    margin: 0 auto;
  }
  .home .home-process__content img.pc {
    display: none;
  }
  .home .home-process__content img.mobile {
    display: block;
  }
  .home .home-process__content img {
    max-width: 502px;
    width: 90%;
  }
}

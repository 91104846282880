/* benefit */
.home .home-benefit {
  width: 100%;
  background-color: #23abb1;
  padding: 175px 0 325px;
}
.home .home-benefit h1 {
  padding-bottom: 50px;
  color: #00fff0;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  text-align: center;
  letter-spacing: 0;
}
.home .home-benefit ul {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  border-top: 2px solid #00fff0;
  padding-top: 60px;
}
.home .home-benefit ul li {
  width: 100%;
}

.home .home-benefit ul li span {
  color: #fff;
  text-align: center;
}

.home .home-benefit ul li h2 {
  font-weight: 600;
  text-align: center;
  color: #fff;
  font-size: var(--font-size--h6);
}

.home .home-benefit ul li h3 {
  font-weight: 600;
  text-align: center;
  color: #fff;
  font-size: var(--font-size--h6);
  margin-bottom: 20px;
}
.home .home-benefit ul li div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  height: 130%;
  align-items: center;
  justify-content: center;
}
.home .home-benefit ul li:nth-of-type(1) div img {
  height: 100px;
}
.home .home-benefit ul li:nth-of-type(2) div img {
  height: 100px;
}
.home .home-benefit ul li:nth-of-type(3) div img {
  height: 100px;
}
.home .home-benefit ul li p {
  margin-top: 15px;
  max-width: 310px;
  font-size: var(--font-size--text2);
  font-weight: 400;
  line-height: var(--liheight--text);
}

@media screen and (max-width: 1024px) {
  .home .home-benefit ul {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .home .home-benefit ul li {
    width: 55%;
  }
  .home .home-benefit ul li div {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .home .home-benefit {
    padding: 100px 0;
  }
  .home .home-benefit .home__inner {
    padding: 0 30px;
  }
  .home .home-benefit ul {
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .home .home-benefit ul li {
    margin: 0 auto;
    padding: 20px 0px;
    width: 100%;
    max-width: 340px;
  }
  .home .home-benefit ul li p {
    height: auto;
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 1.5;
  }
  .home .home-benefit ul li:nth-of-type(1) div img {
    height: 55px;
  }
  .home .home-benefit ul li:nth-of-type(2) div img {
    height: 62px;
  }
  .home .home-benefit ul li:nth-of-type(3) div img {
    height: 62px;
  }
}

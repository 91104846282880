.home {
  width: 100%;
}
.home .home__inner {
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 100%;
}

/* suggestion */
.home .home-suggestion {
  padding: 200px 0;
  background-color: #f9f9f9;
}
.home .home-suggestion__inner {
  text-align: center;
}
.home .home-suggestion__inner h2 {
  padding-bottom: 80px;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  letter-spacing: 0;
}
.home .home-suggestion__inner ul {
  display: flex;
  justify-content: space-between;
}
.home .home-suggestion__inner ul li {
  padding: 0 10px;
  width: calc(100% / 4);
}
.home .home-suggestion__inner ul li p {
  font-size: var(--font-size--text2);
  font-weight: 400;
}
.home .home-suggestion__inner ul li img {
  margin-bottom: 25px;
  height: 70px;
}

/* subscribe__guide */
.home .home-subscribe__guide {
  padding: var(--vertical-space) var(--horizontal-space);
  background-color: #fcfcfa;
}
.home .home-subscribe__guide__menu {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.home .home-subscribe__guide__menu li {
  padding: 0 10px;
  padding-bottom: 10px;
  width: 100%;
  color: #999;
  font-size: var(--font-size--text1);
  text-align: center;
}
.home .home-subscribe__guide__menu li.active {
  color: #15336e;
  font-weight: 600;
  border-bottom: 4px solid #15336e;
}
.home .home-subscribe__guide__content {
  padding-top: 60px;
  padding-bottom: 40px;
  color: #4c4c4c;
  font-size: var(--font-size--text1);
}
.home .home-subscribe__guide__content.product {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
}
.home .home-subscribe__guide__content.product h4 {
  padding-bottom: 35px;
  font-size: var(--font-size--h6);
  font-weight: 600;
  color: #3b3f46;
}
.home .home-subscribe__guide__content.product div:nth-child(1) table {
  position: relative;
}
.home
  .home-subscribe__guide__content.product
  div:nth-child(1)
  table
  > div.blur-img {
  position: absolute;
  top: 0;
  width: 690px;
  height: 285px;
  background-color: rgba(255, 255, 255, 0.7);
}
.home .home-subscribe__guide__content.product div:nth-child(1) table thead th {
  font-size: var(--font-size--sub-content);
  padding: 10px 50px;
  border-top: 1px solid #15336e;
  border-bottom: 1px solid #15336e;
  text-align: center;
  background-color: #f8fafc;
  font-weight: 600;
}
.home .home-subscribe__guide__content.product div:nth-child(1) table tbody th {
  font-size: var(--font-size--sub-content);
  padding: 10px 0;
  border-top: 1px solid #15336e;
  border-bottom: 1px solid #15336e;
  text-align: center;
  font-weight: 600;
}
.home .home-subscribe__guide__content.product div:nth-child(1) table tbody td {
  font-size: var(--font-size--sub-content);
  padding: 10px 0;
  border-top: 1px solid #15336e;
  border-bottom: 1px solid #15336e;
  text-align: center;
}
.home .home-subscribe__guide__content.product table .text-color {
  color: #ec5b44;
}
.home .home-subscribe__guide__content.product table td.text-color {
  font-size: var(--font-size--h3);
}
.home .home-subscribe__guide__content.product div:nth-child(1) {
  width: 980px;
}
.home .home-subscribe__guide__content.product div:nth-child(1) p {
  margin-top: 25px;
  font-size: var(--font-size--sub-content);
}
.home .home-subscribe__guide__content.product div:nth-child(2) {
  width: 590px;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  overflow-x: auto;
  overflow-y: hidden;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table tbody {
  display: flex;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table th {
  display: block;
  width: 235px;
  font-size: var(--font-size--sub-content);
  border-top: 1px solid #15336e;
  border-bottom: 1px solid #15336e;
  text-align: center;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table thead th {
  background-color: #f8fafc;
  font-weight: 600;
}
.home
  .home-subscribe__guide__content.product
  div:nth-child(2)
  table
  thead
  th:nth-of-type(1) {
  height: 110px;
  line-height: 110px;
  border-bottom: none;
}
.home
  .home-subscribe__guide__content.product
  div:nth-child(2)
  table
  thead
  th:nth-of-type(2) {
  padding-top: 40px;
  height: 180px;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table tbody th {
  padding-top: 20px;
  width: 185px;
  height: 110px;
  border-bottom: none;
  font-weight: 600;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table tbody td {
  height: 180px;
  line-height: 180px;
}
.home .home-subscribe__guide__content.product div:nth-child(2) table td {
  display: block;
  font-size: var(--font-size--sub-content);
  border-top: 1px solid #15336e;
  border-bottom: 1px solid #15336e;
  text-align: center;
}
.home .home-subscribe__guide__content.benefit {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  color: #000;
}
.home .home-subscribe__guide__content.benefit div {
  padding: 0 40px 40px;
  width: 740px;
  height: 295px;
  border: 4px solid #d9d9d9;
  border-radius: 15px;
}
.home .home-subscribe__guide__content.benefit div h4 {
  padding-top: 50px;
  padding-left: 80px;
  padding-bottom: 20px;
  font-size: var(--font-size--h6);
  font-weight: 600;
  border-bottom: 1px solid #15336e;
}
.home .home-subscribe__guide__content.benefit div:nth-child(1) h4 {
  background: url(/public/images/report1.png) no-repeat 0% 70%;
}
.home .home-subscribe__guide__content.benefit div:nth-child(2) h4 {
  background: url(/public/images/report2.png) no-repeat 0% 70%;
}
.home .home-subscribe__guide__content.benefit div p {
  padding-top: 20px;
  font-size: var(--font-size--sub-content);
}
.home .home-subscribe__guide__content.guide ul {
  display: flex;
  gap: 70px;
  flex-wrap: wrap;
}
.home .home-subscribe__guide__content.guide ul > li {
  position: relative;
  width: 250px;
}
.home .home-subscribe__guide__content.guide ul li:last-of-type {
  padding-right: 0;
}
.home .home-subscribe__guide__content.guide ul li div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 225px;
  border: 4px solid #d9d9d9;
  border-radius: 15px;
  text-align: center;
}
.home .home-subscribe__guide__content.guide ul li div p {
  font-size: var(--font-size--text1);
  font-weight: 600;
  color: #000;
}
.home .home-subscribe__guide__content.guide ul li div p span {
  font-size: var(--font-size--sub-content);
  font-weight: normal;
}
.home .home-subscribe__guide__content.guide ul li div > img:nth-of-type(2) {
  position: absolute;
  right: -70px;
}
.home .home-subscribe__guide__content.guide ul li ol {
  margin-top: 20px;
}
.home .home-subscribe__guide__content.guide ul li ol li {
  white-space: nowrap;
  color: #000;
  font-size: var(--font-size--sub-content);
  font-weight: 600;
}
.home .home-subscribe__guide__content.re100 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.home .home-subscribe__guide__content.re100 div:nth-of-type(1) {
  max-width: 760px;
}
.home .home-subscribe__guide__content.re100 div:nth-of-type(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  max-width: 760px;
  height: auto;
  border: 4px solid #d9d9d9;
  border-radius: 15px;
}
.home .home-subscribe__guide__content.re100 div:nth-of-type(2) img {
  height: auto;
  width: 90%;
}
.home .home-subscribe__guide__content.kre100 h4 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: var(--font-size--h6);
  font-weight: 600;
  color: #000;
  border-bottom: 1px solid #15336e;
}
.home .home-subscribe__guide__content.kre100 ul {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}
.home .home-subscribe__guide__content.kre100 li {
  padding: 45px;
  width: 770px;
  border: 4px solid #d0d0d0;
  border-radius: 15px;
  background-color: #fff;
}
.home .home-subscribe__guide__content.kre100 li:nth-of-type(1) div,
.home .home-subscribe__guide__content.kre100 li:nth-of-type(2) div {
  height: 240px;
}
.home .home-subscribe__guide__content.kre100 li div img {
  width: 100%;
}
.home .home-subscribe__guide__content.kre100 li p {
  font-size: var(--font-size--sub-content);
  color: #000;
  border-top: 1px solid #15336e;
  padding-top: 20px;
}
.home .home-subscribe__guide__content.kre100 li:nth-of-type(1),
.home .home-subscribe__guide__content.kre100 li:nth-of-type(2) {
  height: 460px;
}
.home .home-subscribe__guide__content.kre100 li:nth-of-type(3),
.home .home-subscribe__guide__content.kre100 li:nth-of-type(4) {
  height: 625px;
}
.home .home-subscribe__guide__content.kre100 div:nth-of-type(2) {
  margin-top: 40px;
  font-size: var(--font-size--text1);
  color: #3b3f46;
}
.home .home-subscribe__guide__content.sixty {
  display: flex;
  flex-wrap: wrap;
}
.home .home-subscribe__guide__content.sixty > div {
  margin-bottom: 50px;
  width: 50%;
  min-width: 540px;
  min-height: 500px;
}
.home .home-subscribe__guide__content.sixty > div:nth-of-type(1) {
  padding-right: 50px;
}
.home .home-subscribe__guide__content.sixty > div:nth-of-type(2) {
  background: url(/public/images/sixty_bg.png) no-repeat 50%;
  background-size: cover;
}

/* 
.sixty-re100__badge {
  z-index: 1000;
  position: fixed;
  right: 70px;
  bottom: 70px;
  display: block;
  background-color: white;
  padding: 15px 20px;
  border-radius: 7px;
}
.sixty-re100__badge-image {
  width: 200px;
  height: auto;
  cursor: pointer;
}
.sixty-re100__badge .text {
  padding-top: 10px;
  font-size: var(--font-size--text2);
  color: var(--color--gray-3);
  text-align: center;
} */

/* 모바일버전 반응형 */
@media screen and (max-width: 767px) {
  .home .home__inner {
    width: auto;
    margin: 0 var(--horizontal-space);
  }
  .home .home-suggestion {
    padding: 100px 0;
  }
  .home .home-suggestion__inner h2 {
    margin: 0 auto;
    padding-bottom: 60px;
    max-width: 200px;
  }
  .home .home-suggestion__inner ul {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 40px;
  }
  .home .home-suggestion__inner ul li {
    width: 50%;
    gap: 20px;
  }
  .home .home-suggestion__inner ul li img {
    margin-bottom: 15px;
    height: 60px;
  }
}

.header {
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: transparent;
}
.header.show {
  background-color: #fff;
}
.header nav.mobile {
  display: none;
}
.header .mobile_menu {
  display: none;
}
.header .header-inner {
  position: relative;
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .header__logo {
  display: inline-block;
  height: 33px;
  cursor: pointer;
}
.header .header__logo img {
  height: 100%;
  cursor: pointer;
}
.header .header__nav-menu {
  display: flex;
  align-items: center;
}
.header .mobile_menu {
  display: none;
}
.header .header__nav-menu li {
  font-size: var(--font-size--text3);
}
.header .header__nav-menu li a {
  padding: 25px 0;
  display: block;
  margin-left: 55px;
  white-space: nowrap;
  font-weight: 500;
}
.header .header__nav-menu li a:last-of-type {
  padding-right: 0;
}
.header .header__nav-menu li a:hover {
  cursor: pointer;
  color: #008d6b;
}

/* 태블릿버전 반응형 */
@media screen and (max-width: 1024px) {
  .header .header__nav-menu li a {
    margin-left: 35px;
  }
}

/* 모바일버전 반응형 */
@media screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
  .header .header__logo {
    height: 26px;
  }
  .header.show.hamActive {
    border-bottom: none;
  }
  header.hamActive {
    background-color: #fff;
  }
  .header .header-inner {
    width: auto;
    padding: 0 var(--horizontal-space);
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .header nav.pc {
    display: none;
  }
  .header nav.mobile {
    position: relative;
    display: block;
    width: 50px;
    height: 100%;
    background-image: url(/public/images/ham.png);
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .header nav.mobile:hover,
  .header nav.mobile span:hover {
    cursor: pointer;
  }
  .header nav.mobile.active {
    background-image: url(/public/images/hamClose.png);
  }
  .header .header-inner ul.mobile_menu {
    display: none;
    position: fixed;
    top: 55px;
    left: 0px;
    padding-top: 40px;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
  }
  .header .header-inner ul.mobile_menu.active {
    display: block;
  }
  .header .header-inner ul li {
    line-height: 2;
  }
  .header .header-inner ul li a {
    display: block;
    padding: 15px 0 15px 35px;
    width: 100%;
    font-size: var(--font-size--h6);
    font-weight: 500;
  }
  .header .header-inner ul li a:hover {
    cursor: pointer;
    color: #008d6b;
  }
  .home .home-banner__title p:nth-of-type(2) .font_color {
    line-height: 1;
  }
}

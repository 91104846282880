.member-box {
  background-color: #e5e5e5;
}
.members {
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 100%;
}
.members section {
  margin-bottom: 30px;
}
.members .section1,
.members .section3,
.members .section4 {
  border-radius: 30px;
  background-color: #fff;
}
.members .font-somice {
  font-family: "somice";
}
/* title */
.members .title {
  padding: 120px 0 100px;
}
.members .title .sub-title {
  padding-bottom: 20px;
  font-size: var(--font-size--h6);
  font-weight: 600;
  text-align: center;
}
.members .title h2 {
  font-size: var(--font-size--h1);
  font-weight: 600;
  text-align: center;
}
.members .title .date {
  padding-top: 30px;
  font-size: var(--font-size--text1);
  text-align: center;
  font-weight: 600;
}
/* section1 */
.members .section1 {
  width: 100%;
}
.members .section1 .inner {
  padding: 80px 0;
  width: 90%;
  margin: 0 auto;
}
.members .section1 img {
  margin: 0 auto;
  display: block;
  max-width: 874px;
  width: 100%;
}
/* section2 */
.members .section2 {
  width: 100%;
}
.members .section2 ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.members .section2 ul li {
  padding: 80px 0;
  width: calc(100% / 3);
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
}
.members .section2 ul li p:nth-child(1) {
  font-size: var(--font-size--h1);
  font-weight: 600;
}
.members .section2 ul li p:nth-child(2) {
  padding-top: 10px;
  font-size: var(--font-size--text2);
  font-weight: 600;
}
.members .section2 ul li img {
  padding-left: 5px;
  height: 68px;
}
/* section3 */
.members .section3 {
  width: 100%;
}
.members .section3 .inner {
  padding: 70px 0;
  width: 90%;
  margin: 0 auto;
}
.members .section3 h3 {
  margin: 0 auto;
  padding-bottom: 35px;
  width: 100%;
  font-size: var(--font-size--h3);
  font-weight: 600;
  text-align: center;
}
.members .section3 img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
/* section4 */
.members .section4 {
  width: 100%;
}
.members .section4 .inner {
  padding: 70px 0 0 60px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 45px;
  /* width: 90%; */
}
.members .section4 .left-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
}
.members .section4 .left-box h3 {
  padding-bottom: 40px;
  font-size: var(--font-size--h3);
  font-weight: 600;
}
.members .section4 .left-box h4 {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 10px;
  font-size: var(--font-size--h5);
  font-weight: 600;
}

.members .section4 .left-box h4 a {
  margin-bottom: 7px;
}

.members .section4 .left-box h4 span {
  padding: 0 5px 8px;
  font-size: var(--font-size--text5);
  color: #00d958;
  background-image: url(../../../public/images/members/section4_underline.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 100%;
  cursor: pointer;
}
.members .section4 .left-box p {
  font-size: var(--font-size--text1);
}
.members .section4 .left-box > div > div:nth-child(1) {
  padding-bottom: 40px;
  border-bottom: 1px solid #d3d3d3;
}
.members .section4 .left-box > div > div:nth-child(2) {
  padding: 40px 0;
}
.members .section4 .right-box {
  display: flex;
  align-items: flex-end;
}
.members .section4 .right-box > div {
  position: relative;
  display: inline-block;
}
.members .section4 .right-box > div img {
  width: 100%;
  max-width: 400px;
  border-bottom-right-radius: 15px;
}
.members .section4 .right-box > div .text {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  font-size: var(--font-size--text5);
}
.members .section4 .right-box > div .text dl {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #1D1D1F;
}
.members .section4 .right-box > div .text dl:last-child {
  border-bottom: none;
}
.members .section4 .right-box > div .text dl dd {
  font-weight: 600;
}
.members footer {
  padding: 110px 0;
  width: 100%;
}
.members footer .inner {
  position: relative;
  display: flex;
  gap: 70px;
}
.members footer .inner .text-box {
  display: flex;
  gap: 50px;
}
.members footer .inner h3 {
  font-weight: 600;
}
.members footer .inner li {
  font-size: var(--font-size--text3);
  line-height: 1.7;
}
.members footer .inner li .home-link,
.members footer .inner li .mail-to {
  cursor: pointer;
}
.members footer .inner .logo {
  width: 177px;
  cursor: pointer;
}
.members footer .inner .badge {
  position: absolute;
  right: 0;
  width: 180px;
}
.members footer .inner .badge img {
  width: 100%;
}
@media screen and (max-width: 1700px) {
  .members {
    /* padding: 0 220px; */
  }
}
@media screen and (max-width: 1400px) {
  .members .section1 .inner {
    padding: 50px 0;
  }
}
@media screen and (max-width: 1024px) {
  .members .title {
    padding: 100px 0 80px;
  }
  .members .section1 .inner {
    width: 65%;
  }
  .members .section2 ul {
    gap: 20px;
  }
  .members .section2 ul li {
    padding: 40px 0;
  }
  .members .section2 ul li p:nth-child(1) {
    font-size: var(--font-size--h2);
  }
  .members .section2 ul li img {
    height: 50px;
  }
  .members .section3 .inner {
    padding: 50px 0;
  }
  .members .section4 .inner {
    width: 90%;
    padding: 70px 0px 40px 0px;
    flex-direction: column;
    gap: 0px;
  }
  .members .section4 .left-box {
    width: 100%;
  }
  .members .section4 .left-box h4 {
    gap: 10px;
    padding-bottom: 10px;
  }
  .members .section4 .right-box {
    justify-content: center;
  }
  .members .section4 .right-box > div img {
    max-width: 350px;
  }
  .members .section4 .right-box img {
    border-radius: 15px;
  }
  .members footer {
    padding: 70px 0;
  }
  .members footer .inner {
    flex-direction: column;
    gap: 30px;
  }
  .members footer .inner .logo {
    width: 155px;
  }
}
@media screen and (max-width: 767px) {
  .members .section1,
  .members .section3,
  .members .section4 {
    border-radius: 15px;
  }
  .members section {
    margin-bottom: 25px;
  }
  .members .title {
    padding: 80px 0 60px;
  }
  .members .title .sub-title {
    padding-bottom: 10px;
  }
  .members .title .date {
    padding-top: 15px;
  }
  .members .section1 .inner {
    padding: 35px 0;
    width: 70%;
  }
  .members .section2 ul {
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }
  .members .section2 ul li {
    padding: 30px 0;
    width: 49%;
    border-radius: 12px;
  }
  .members .section2 ul li img {
    height: 35px;
  }
  .members .section4 .inner {
    padding: 40px 0;
  }
  .members .section4 .left-box h3 {
    padding-bottom: 30px;
  }
  .members .section4 .left-box > div > div:nth-child(1) {
    padding-bottom: 20px;
  }
  .members .section4 .left-box > div > div:nth-child(2) {
    padding: 20px 0;
  }
  .members .section4 .left-box h4 {
    padding-bottom: 5px;
  }
  .members .section4 .right-box img {
    margin-bottom: 0;
    width: 80%;
  }
  .members footer {
    padding: 40px 0 120px;
  }
  .members footer .inner .logo {
    width: 130px;
  }
  .members footer .inner .badge {
    right: auto;
    left: 0;
    bottom: -50px;
    width: 130px;
  }
  .members .section4 .right-box {
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .members .section1 .inner {
    padding: 40px 0;
  }
  .members .section2 ul {
    column-gap: 7px;
    row-gap: 10px;
  }
  .members .section2 ul li {
    padding: 30px 0;
    width: 49%;
  }
}
@media screen and (max-width: 500px) {
  .members section {
    margin-bottom: 20px;
  }
  .members .title {
    padding: 60px 0 50px;
  }
  .members .section1 .inner {
    padding: 30px 0;
    width: 80%;
  }
  .members .section2 ul li {
    margin: 0 auto;
    padding: 15px 0;
    width: 75%;
  }
  .members .section3 .inner {
    padding: 40px 0;
  }
  .members .section3 h3 {
    padding-bottom: 20px;
  }
  .members .section4 .left-box h4 {
    gap: 7px;
  }
  .members footer .inner {
    gap: 30px;
  }
  .members footer .inner .text-box {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 400px) {
  .members .section1,
  .members .section3,
  .members .section4 {
    border-radius: 12px;
  }
  .members .section1 .inner {
    padding: 20px 0;
  }
  .members .section2 ul li img {
    height: 30px;
  }
  .members .section2 ul li {
    border-radius: 12px;
  }
}

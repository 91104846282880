/* subscribe-calculation */
.home .home-subscribe-calculation {
  width: 100%;
}
.home .home-subscribe-calculation .home__inner {
  max-width: 930px;
  width: var(--horizontal-width--responsive);
}
.home .home-subscribe-calculation .title {
  padding: 200px 0 0px;
}
.home .home-subscribe-calculation .title h3 {
  font-size: var(--font-size--h1);
  font-weight: var(--font-weight--h2);
  text-align: center;
  color: #00aaae;
  line-height: 1.3;
}
.home .home-subscribe-calculation .title h3 span {
  font-weight: 400;
  color: #00aaae;
  line-height: 1.3;
}
.home .home-subscribe-calculation .title p {
  padding-top: 15px;
  font-size: var(--font-size--text1);
  text-align: center;
}
.home .home-subscribe-calculation .content {
  padding: 120px 0 50px;
  /* background-color: #f0f0f0; */
}
/* .home .home-subscribe-calculation .content .home__inner > div {
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
} */
.home .home-subscribe-calculation .content h2 {
  padding-bottom: 80px;
  text-align: center;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  letter-spacing: 0;
}
.home .home-subscribe-calculation .content .form-box {
  display: flex;
  gap: 40px;
}
.home .home-subscribe-calculation .content .form-box form,
.home .home-subscribe-calculation .content .form-box .result-box {
  width: 50%;
  height: 165px;
}
.home .home-subscribe-calculation .content .form-box form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home .home-subscribe-calculation .content .form-box form p {
  padding-bottom: 7px;
  font-size: var(--font-size--text2);
  font-weight: 400;
}
.home .home-subscribe-calculation .content .form-box form .area {
  position: relative;
}
.home .home-subscribe-calculation .content .form-box form .area p {
  position: absolute;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.home .home-subscribe-calculation .content .form-box form input[type="text"] {
  width: 100%;
  height: var(--input--height);
  padding: 0 10px;
  font-size: var(--font-size--text2);
  border-radius: 5px;
  border: var(--input--border-line) solid var(--input--border-color);
  box-shadow: none;
}
.home .home-subscribe-calculation .content .form-box form input.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--input--height);
  width: 100%;
  border-radius: 5px;
  color: #fff;
  font-size: var(--font-size--text1);
  border: none;
}
.home .home-subscribe-calculation .content .form-box form input[type="button"] {
  background-color: #c9c9c9;
}
.home .home-subscribe-calculation .content .form-box form input[type="submit"] {
  background-color: var(--button-color);
  cursor: pointer;
}
.home .home-subscribe-calculation .content .form-box form input::placeholder {
  color: var(--color--gray-4);
}
.home .home-subscribe-calculation .content .form-box .result-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
  background-color: #f8f8f8;
  border-radius: 10px;
  font-size: var(--font-size--text3);
}
.home .home-subscribe-calculation .content .form-box .result-box.text {
  font-size: var(--font-size--text2);
  line-height: var(--liheight--1);
}
.home .home-subscribe-calculation .content .form-box .result-box p {
  display: flex;
  align-items: center;
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0;
}
.home .home-subscribe-calculation .content .form-box .result-box span {
  padding-left: 10px;
  padding-right: 3px;
  font-weight: 600;
}
.home .home-subscribe-calculation .content .form-box .result-box img {
  margin: 0 3px 0 7px;
  width: 44px;
}
.home .home-subscribe-calculation .content .text-box {
  padding-top: 25px;
}
.home .home-subscribe-calculation .content .text-box ul:nth-child(2) {
  display: none;
}
.home .home-subscribe-calculation .content .text-box li {
  font-size: var(--font-size--text2);
  color: var(--color--gray-3);
  line-height: 32px;
}
.home .home-subscribe-calculation .content .text-box li > p {
  font-size: var(--font-size--text4);
  color: var(--color--gray-3);
  margin: 5px 10px;
}
.home .home-subscribe-calculation .content .text-box li.mailto a {
  margin-left: 4px;
  color: var(--color--gray-3);
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .home .home-subscribe-calculation .content .form-box .result-box p {
    font-size: 24px;
    line-height: 1.8;
  }
  .home .home-subscribe-calculation .content .form-box .result-box img {
    width: 34px;
  }
}
@media screen and (max-width: 767px) {
  .home .home-subscribe-calculation .home__inner {
    width: auto;
  }
  .home .home-subscribe-calculation .title {
    padding: 100px 0 0px;
  }
  .home .home-subscribe-calculation .title p {
    padding-top: 7px;
  }
  .home .home-subscribe-calculation .content {
    padding: 100px 10px 30px;
  }
  .home .home-subscribe-calculation .content h2 {
    padding-bottom: 50px;
  }
  .home .home-subscribe-calculation .title h3 {
    line-height: 1.4;
  }
  .home .home-subscribe-calculation .title h3 span {
    line-height: 1.4;
  }
  .home .home-subscribe-calculation .content .form-box {
    gap: 30px;
  }
  .home .home-subscribe-calculation .content .form-box {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .home .home-subscribe-calculation .content .form-box form,
  .home .home-subscribe-calculation .content .form-box .result-box {
    width: 100%;
    height: 140px;
  }
  .home .home-subscribe-calculation .content .form-box form input,
  .home .home-subscribe-calculation .content .form-box form .btn {
    height: 47px;
  }
  .home .home-subscribe-calculation .content .form-box .result-box {
    padding: 0 25px;
  }
  .home .home-subscribe-calculation .content .form-box .result-box img {
    margin: 0 2px 0 4px;
    width: 28px;
  }
  .home .home-subscribe-calculation .content .form-box .result-box p {
    line-height: 1.7;
    font-size: 20px;
  }
  .home .home-subscribe-calculation .content .text-box {
    padding-top: 15px;
  }
  .home .home-subscribe-calculation .content .text-box li {
    line-height: 1.4;
  }
}
@media screen and (max-width: 320px) {
  .home .home-subscribe-calculation .content .form-box .result-box p {
    font-size: 16px;
  }
  .home .home-subscribe-calculation .content .form-box .result-box img {
    width: 26px;
  }
}

/* partners */
.home .home-partners {
  padding: 200px 0;
}
.home .home-partners__title {
  margin: 0 auto 80px;
  width: 80%;
  text-align: center;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  letter-spacing: 0;
}
.home .home-partners__title.mobile {
  display: none;
}
.home .home-partners .slide-box {
  position: relative;
  height: 170px;
  overflow: hidden;
}
.home .home-partners .slide-box .next_btn {
  display: none;
}
.home .home-partners__logos {
  width: 300%;
  position: absolute;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.home .home-partners__logos li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 10);
  height: auto;
  text-align: center;
}
.home .home-partners__logos li img {
  margin: 0 auto;
  display: block;
}
.home .home-partners__logos li.logo_1 img {
  width: 130px;
}
.home .home-partners__logos li.logo_2 img {
  width: 120px;
}
.home .home-partners__logos li.logo_3 img {
  width: 190px;
}
.home .home-partners__logos li.logo_4 img {
  width: 200px;
}
.home .home-partners__logos li.logo_5 img {
  width: 240px;
}
.home .home-partners__logos li.logo_6 img {
  width: 240px;
}
.home .home-partners__logos li.logo_7 img {
  width: 170px;
}
.home .home-partners__logos li.logo_8 img {
  width: 210px;
}
@media screen and (max-width: 1024px) {
  .home .home-partners__logos li.logo_1 img {
    width: 97.5px;
  }
  .home .home-partners__logos li.logo_2 img {
    width: 90px;
  }
  .home .home-partners__logos li.logo_3 img {
    width: 142.5px;
  }
  .home .home-partners__logos li.logo_4 img {
    width: 150px;
  }
  .home .home-partners__logos li.logo_5 img {
    width: 180px;
  }
  .home .home-partners__logos li.logo_6 img {
    width: 180px;
  }
  .home .home-partners__logos li.logo_7 img {
    width: 127.5px;
  }
  .home .home-partners__logos li.logo_8 img {
    width: 157.5px;
  }
}
@media screen and (max-width: 767px) {
  .home .home-partners {
    padding: 120px 0 140px;
  }
  .home .home-partners__title {
    margin-bottom: 30px;
  }
  .home .home-partners .slide-box {
    height: 87px;
  }
  .home .home-partners__logos li.logo_1 img {
    width: 70px;
  }
  .home .home-partners__logos li.logo_2 img {
    width: 65px;
  }
  .home .home-partners__logos li.logo_3 img {
    width: 95px;
  }
  .home .home-partners__logos li.logo_4 img {
    width: 100px;
  }
  .home .home-partners__logos li.logo_5 img {
    width: 140px;
  }
  .home .home-partners__logos li.logo_6 img {
    width: 140px;
  }
  .home .home-partners__logos li.logo_7 img {
    width: 90px;
  }
  .home .home-partners__logos li.logo_8 img {
    width: 110px;
  }
}
@media screen and (max-width: 500px) {
  .home .home-partners__logos {
    width: 600%;
  }
}

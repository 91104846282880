/* service-detail */
.home .home-service-detail {
  padding: 200px 0 130px;
}
.home .home-service-detail h2 {
  padding-bottom: 80px;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  text-align: center;
  letter-spacing: 0;
}
.home .home-service-detail__content .title img {
  display: none;
  width: 56px;
}
.home .home-service-detail__content .title {
  padding: 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 2px solid #f2f2f2;
  cursor: pointer;
}
.home .home-service-detail__content .title h3,
.home .home-service-detail__content .title img,
.home .home-service-detail__content .title .btn {
  cursor: pointer;
}

.home .home-service-detail__content .title h3 {
  padding-right: 24px;
  width: 100%;
  font-size: var(--font-size--h5);
  font-weight: 600;
}
.home .home-service-detail__content .title .btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 14px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../../../../public/images/home/detail_btn_down.png);
}
.home .home-service-detail__content .title .btn.show {
  background-image: url(../../../../public/images/home/detail_btn_up.png);
}
.home .home-service-detail__content .content {
  display: none;
  padding: 20px 0 50px;
}
.home .home-service-detail__content .content.show {
  display: block;
}
.home .home-service-detail__content .content .text {
  padding-bottom: 15px;
}
.home .home-service-detail__content .content .text span {
  margin-top: 5px;
  display: block;
  color: var(--color--gray-3);
}
.home .home-service-detail__content .content p {
  font-size: var(--font-size--text2);
  line-height: 1.5;
}
.home .home-service-detail__content .content p.mobile {
  display: none;
}
.home .home-service-detail__content .content p strong {
  font-weight: 600;
}
.home .home-service-detail__content ul li:nth-of-type(1) .content img {
  width: 100%;
}
.home .home-service-detail__content ul li:nth-of-type(3) .content .img_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.home .home-service-detail__content ul li:nth-of-type(3) .content .img_box div {
  padding: 10px;
  width: 50%;
}
.home .home-service-detail__content ul li:nth-of-type(3) .content .img_box img {
  width: 100%;
}
.home .home-service-detail__content ul li:nth-of-type(2) .content .img_box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
.home
  .home-service-detail__content
  ul
  li:nth-of-type(2)
  .content
  .img_box.mobile {
  display: none;
}
.home
  .home-service-detail__content
  ul
  li:nth-of-type(2)
  .content
  .img_box
  img:nth-of-type(1) {
  width: 66%;
}
.home
  .home-service-detail__content
  ul
  li:nth-of-type(2)
  .content
  .img_box
  img:nth-of-type(2) {
  width: 33%;
}
@media screen and (max-width: 767px) {
  .home .home-service-detail__content .content p .underline {
    background-position: 0 10px;
  }

  .home .home-report {
    padding: 100px 0 0px;
  }

  .home .home-service-detail {
    padding: 100px 0 50px;
  }
  .home .home-service-detail h2 {
    padding-bottom: 60px;
  }
  .home-service-detail__content {
    padding: 0 10px;
  }
  .home .home-service-detail__content .content p.pc {
    display: none;
  }
  .home .home-service-detail__content .content p.mobile {
    display: block;
  }
  .home .home-service-detail__content .title img {
    width: 40px;
  }
  .home .home-service-detail__content .title .btn {
    width: 16px;
    height: 10px;
  }
  .home .home-service-detail__content .content {
    padding: 15px 0 40px;
  }
  .home .home-service-detail__content .content .text {
    padding-bottom: 10px;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(3)
    .content
    .img_box
    div {
    padding: 5px 0;
    width: 100%;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(2)
    .content
    .img_box.pc {
    display: none;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(2)
    .content
    .img_box.mobile {
    display: block;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(2)
    .content
    .img_box
    div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(2)
    .content
    .img_box
    div
    img:nth-child(1) {
    width: 100%;
  }
  .home
    .home-service-detail__content
    ul
    li:nth-of-type(2)
    .content
    .img_box
    div
    img:nth-child(2) {
    width: 100%;
  }
}

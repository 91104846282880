/* monthly-fee */
.home .home-monthly-fee {
  display: none;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
.home .home-monthly-fee__inner {
  height: 100%;
  background-color: #073b52;
}
.home .home-monthly-fee__title {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #2dd5d7;
  font-size: var(--font-size--h5);
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}
.home .home-monthly-fee__content .table-img {
  margin: 0 auto;
  display: block;
  max-width: 926px;
  width: 90%;
}
.home .home-monthly-fee__content .table-img.mobile {
  display: none;
}
.home .home-monthly-fee__title img {
  position: relative;
  width: 28px;
}
.home .home-monthly-fee__content {
  display: none;
  padding-top: 25px;
  background-color: #fff;
}
.home .home-monthly-fee__content.show {
  display: block;
}
.home .home-monthly-fee__content .table {
  margin: 0 auto;
  padding: 25px 0;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
}
.home .home-monthly-fee__content .table h3 {
  padding-bottom: 15px;
  font-size: var(--font-size--text2);
  font-weight: 600;
  text-align: center;
}
.home .home-monthly-fee__content .table table {
  margin: 0 auto;
}
.home .home-monthly-fee__content .table table th,
.home .home-monthly-fee__content .table table td {
  padding: 15px 0;
  border-top: 0.5px solid #000;
  font-size: var(--font-size--text4);
}
.home .home-monthly-fee__content .table table thead th {
  font-weight: 600;
  text-align: center;
}
.home .home-monthly-fee__content .table table thead th:nth-child(1) {
  border-top: none;
}
.home .home-monthly-fee__content .table table tbody th {
  padding-right: 25px;
  font-weight: 600;
}
.home .home-monthly-fee__content .table table tbody td {
  width: 170px;
  text-align: center;
  color: #3c9192;
}
.home .home-monthly-fee__content .table table tbody tr:last-child th,
.home .home-monthly-fee__content .table table tbody tr:last-child td {
  padding-bottom: 0;
}
.home .home-monthly-fee__content .text {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home .home-monthly-fee__content .text > div {
  display: inline-block;
  max-width: 730px;
}
.home .home-monthly-fee__content p {
  margin: 0 auto;
  font-size: var(--font-size--text2);
  color: #3c9192;
  text-align: left;
  line-height: var(--liheight--text);
}
.home .home-monthly-fee__content p.mailto a {
  margin-left: 4px;
  font-size: var(--font-size--text2);
  color: #3c9192;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .home .home-monthly-fee__title {
    gap: 10px;
  }
  .home .home-monthly-fee__title img {
    width: 20px;
  }
  .home .home-monthly-fee__content {
    padding-top: 0;
  }
  .home .home-monthly-fee__content .table {
    padding: 20px 0;
  }
  .home .home-monthly-fee__content .table table tbody th {
    padding-right: 15px;
  }
  .home .home-monthly-fee__content .table table tbody th {
    padding-right: 5px;
  }
  .home .home-monthly-fee__content .table table tbody td {
    width: 90px;
  }
  .home .home-monthly-fee__content .table table th,
  .home .home-monthly-fee__content .table table td {
    padding: 10px 0;
  }
  .home .home-monthly-fee__content .table-img.pc {
    display: none;
  }
  .home .home-monthly-fee__content .table-img.mobile {
    display: block;
  }
  .home .home-monthly-fee__content .text {
    padding-bottom: 20px;
  }
  .home .home-monthly-fee__content p {
    margin: 0 auto;
    width: 80%;
  }
  .home .home-monthly-fee__content p,
  .home .home-monthly-fee__content p.mailto a {
    line-height: 1.4;
  }
}
@media screen and (max-width: 500px) {
  .home .home-monthly-fee__content p,
  .home .home-monthly-fee__content p.mailto a {
    font-size: 14px;
  }
}

/* banner */
.home .home-banner {
  width: 100%;
  height: 620px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.home .home-banner__inner {
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 620px;
}
.home .home-banner .image_box {
  display: flex;
  width: 400%;
  height: 100%;
}
.home .home-banner .image_box li {
  width: 100%;
  height: 100%;
}
.home .home-banner .image_box li .home-banner__container {
  height: 100%;
}
.home .home-banner .image_box li:nth-of-type(1) .home-banner__container {
  background-color: #ceeefc;
}
.home .home-banner .image_box li:nth-of-type(2) .home-banner__container {
  background-color: #ffd954;
}
.home .home-banner .image_box li:nth-of-type(3) .home-banner__container {
  background-color: #d1e9d0;
}
.home .home-banner .image_box li:nth-of-type(4) .home-banner__container {
  background-color: #fcf9ce;
}
.home .home-banner__title {
  position: relative;
  margin: 0 auto;
  padding-top: 220px;
  max-width: var(--inner-max-width);
  height: 100%;
  background-repeat: no-repeat;
}
.home .home-banner__title.banner1 {
  background-image: url(/public/images/home/banner1.png);
  background-size: 40%;
  background-position: 100% 100%;
}
.home .home-banner__title.banner2 {
  background-image: url(/public/images/home/banner2.png);
  background-size: 40%;
  background-position: 100% 100%;
}
.home .home-banner__title.banner3 {
  background-image: url(/public/images/home/banner3.png);
  background-size: 40%;
  background-position: 100% 100%;
}
.home .home-banner__title.banner4 {
  background-image: url(/public/images/home/banner4.png);
  background-size: 40%;
  background-position: 100% 100%;
}
.home .home-banner__title.mobile {
  display: none;
}
.home .home-banner__title .text {
  margin: 0% auto;
  padding-bottom: 10px;
  text-align: left;
  font-size: var(--font-size--h4);
  font-weight: 400;
}
.home .home-banner__title p:nth-of-type(1) {
  font-weight: 500;
  line-height: 1.5;
  font-size: var(--font-size--h5);
}
.home .home-banner__title p:nth-of-type(2) {
  margin: 0 auto;
  text-align: left;
  font-size: var(--font-size--h2);
  font-weight: var(--font-weight--h2);
  line-height: 1.5;
}
.home .home-banner__title p:nth-of-type(2) .font_color {
  color: #008d6b;
  font-weight: var(--font-weight--h2);
}
.home .home-banner .pages_box {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  display: flex;
  gap: 18px;
}
.home .home-banner .pages_box li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.home .home-banner .pages_box li.active {
  background-color: #008d68;
}
@media screen and (max-width: 767px) {
  .home .home-banner,
  .home .home-banner__inner {
    height: 370px;
  }
  .home .home-banner__title {
    padding-top: 90px;
  }
  .home .home-banner__title.pc {
    display: none;
  }
  .home .home-banner__title.mobile {
    display: block;
  }
  .home .home-banner__title p:nth-of-type(1) {
    padding-left: 10px;
    padding-bottom: 7px;
    line-height: 1.3;
  }
  .home .home-banner__title p:nth-of-type(2) {
    padding-left: 10px;
  }
  .home .home-banner__title p.mobile {
    display: none;
  }
  .home .home-banner .pages_box li {
    width: 12px;
    height: 12px;
  }
}

.footer {
  position: relative;
  margin: 0 auto;
  padding: 50px 0;
  width: 100%;
  background-color: #d9d9d9;
}
.footer .footer-inner {
  margin: 0 auto;
  max-width: var(--horizontal-width);
  width: var(--horizontal-width--responsive);
  height: 100%;
  display: flex;
  gap: 70px;
}
.footer h3 {
  font-size: var(--font-size--text3);
  font-weight: 600;
  line-height: 30px;
}
.footer ul li {
  font-size: var(--font-size--text3);
  line-height: 30px;
}
.footer a {
  cursor: pointer;
}
.footer .logo img {
  width: 108px;
  cursor: pointer;
}
.footer .content {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .footer .footer-inner {
    gap: 30px;
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .footer .footer-inner {
    gap: 17px;
  }
  .footer .logo img {
    width: 90px;
  }
  .footer .content {
    flex-direction: column;
    gap: 20px;
  }
  .footer .content .company {
    order: 3;
  }
  .footer .content .service {
    order: 1;
  }
  .footer .content .inquiry {
    order: 2;
  }
}
